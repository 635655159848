#root {
    width: 100%;
    padding: 0;
    margin: 0;
}
.wtta_Container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 0px;
    margin-left: 0px;
    background: #f4f6f6;
}
.navbar {
    width: 100%;
}